.aboutIntroContainer {
  height: 100%;
  display: flex;
}

.introLeft {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-inline: 100px;
}

.companyProfileBtn {
  padding: 8px 12px;
  gap: 8px;
  width: auto;
  height: 40px;
  background: #0e265e;
  border-radius: 2px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #ffffff !important;
  border: none;
}

.introLeft h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 52px;

  letter-spacing: 1.5px;

  color: #181818;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;

    letter-spacing: 1.5px;

    color: #0e265e;
  }
}

.introLeft p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 31px;
  /* or 194% */
  text-align: justify;

  letter-spacing: 0.54px;

  color: #000000;
}

.introRight {
  position: relative;
  text-align: right;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.introPicOne {
  // position:relative;
  // left: 80px;
  // z-index: 2;
  max-width: 519px;
  height: auto !important;
}

.introPicTwo {
  max-width: 452px;
  height: auto !important;
  position: absolute;
  right: 180px;
  top: 90px;
  z-index: 3;
}

// styling for my lappy

@media (max-width: 1400px) {
  .introPicOne {
    max-width: 410px;
  }

  .introPicTwo {
    max-width: 370px;
    top: 48px;
    right: 150px;
  }
}

// for tab
// for tab landscape

@media (max-width:1200px) and (orientation:landscape){
 
  .introLeft{
    padding-inline:7%;
  }

  .introPicTwo{
    max-width: 370px;
    top: 133px;
    right: 150px;
  }

}


// for i pag mini
@media (max-width:1025px) and (orientation:landscape){
  .introLeft{
    padding-inline:7%;
  }

  // .introPicOne{
  //   max-width:350px;
  // }

  .introPicTwo{
    max-width: 373px;
    top: 112px;
    right: 125px;
  }
}

@media (max-width:900px) and (orientation:portrait){
  .aboutIntroContainer{
    height:auto;

  }

  .introLeft{
    padding-inline:7%;
  }

  .introPicOne{
    max-width:350px;
  }

  .introPicTwo{
    max-width: 320px;
    top: 134px;
    right: 73px;
  }
}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
  .aboutIntroContainer{
    padding:0;
  }

  .introLeft{
    padding-inline:39px;
  }

  .introPicOne{
    max-width:300px;
  }

  .introPicTwo{
    max-width:290px;
    top:87px;
    right:109px;
  }
}

// for small mobile landscape
@media (max-width:768px) and (orientation:landscape){
  .introLeft{
    order:2 !important;
    padding-top:20px;
    padding-bottom:20px;
  }

  .introRight{
    order:1 !important;
   
    
  }

  .introPicOne{
    position:relative;
    right:16%;
  }

  .introPicTwo{
    top:21px;
    right:263px;
  }
}

// styling for mobile

@media (max-width: 576px) {
  .aboutIntroContainer {
    padding: 1rem;
  }

  .introLeft {
    padding: 0;
    padding-top: 11%;
  }

  .introLeft h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 64.5%;
    /* or 12px */

    letter-spacing: 0.08em;

    color: #181818;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 64.5%;
      /* or 12px */

      letter-spacing: 0.08em;
      color: #0e265e;
    }
  }

  .introLeft p {
    padding-top: 2%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
    /* or 175% */

    text-align: justify;
    letter-spacing: 0.54px;
    text-transform: capitalize;

    color: #000000;
  }

  .introRight {
    padding-inline: 4%;
  }

  .introPicOne {
    max-width: 250px;
  }

  .introPicTwo {
    max-width: 220px;
    top: 30px;
    right: 30%;
  }
}
