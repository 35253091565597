
.nleContainer{
    width:100%;
    height:100vh;
    background: url("../../../../Assets/Home/whyNleBack.jpeg");
    background-size: cover;
    background-size: center center;
    padding:50px 100px;
    text-align: left;
}

.nleTextContainer{
    width: 43%;
    height: 100%;
    padding:40px 50px 30px 50px;
    background: #F5F5F5;
    text-align: left;
    margin:0px;

    img{
        height:100%;
    }
}

.nleTextContainer h6{
    // font-family: 'Inter';
    // font-style: normal;
    // font-weight: 200;
    // font-size: 16px;
    // line-height: 19px;
    // letter-spacing: 3.5px;
    // text-transform: uppercase;
    // color: #000000;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 4.5px;
    text-transform: uppercase;
    color: #000000;
}

.nleTextContainer h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.nleTextContainer p{
    // font-family: 'Inter';
    // font-style: normal;
    // font-weight: 200;
    // font-size: 16px;
    // line-height: 33px;
    // text-align: justify;
    // color: #000000;
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: 0.54px;
    color: #000000;
}

// for my device
@media (max-width:1400px){
    .nleTextContainer h3{
        line-height:33px;
    }

    .nleTextContainer p{
        line-height: 26px ;
    }
}

// for tab portrait
@media (max-width:900px) and (orientation:portrait){
    .nleContainer{
        height:60vh;
    }

    .nleTextContainer{
        width:75%;
    }
}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
    .nleContainer{
        height:auto;
    }

    .nleTextContainer{
        width:unset;
        height:35%;
    }
}

// for mobile device
@media (max-width: 576px){
    .nleContainer{
        padding:2.5rem 1rem;
        display:flex;
        justify-content: center;
        align-items: center;
        height:auto;

    }

    .nleTextContainer{
        width:100%;
        height:85%;
        padding:20px;

        p{
            font-weight: 300;
            font-size: 12px;
            line-height: 23px;
        }
    }

    .nleTextContainer h6 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 4.5px;
        text-transform: uppercase;
        color: #000000;
    }

    .nleTextContainer h3{
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
    }
}