// .App {
//   text-align: center;
// }
 
// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }


// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// }

.nav-link {
  color: #000000;
  padding: 0 1rem !important;
}




body::-webkit-scrollbar {
  width: 0px;
  // background: transparent; /* make scrollbar transparent */
}
// // @media (min-height: 30em) {
// //   .section {
// //     height: calc(100vh - 120px);
// //   }
// }
@media only screen and (min-width: 975px) {
  .scroller {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    height:100vh;

   
    overflow-y: scroll;
  }

  .scroller::-webkit-scrollbar {
    display: none; /* hides the scrollbar in Chrome, Safari, and Edge */
  }
  // section {
  //   scroll-snap-align:start;
  //   scroll-snap-type: y mandatory;
  //   height: 100vh;
  //   // padding-top: 100px;
  // }


  .h100 {
    min-height: 90vh;
  }
// .ziziContainer{
//   padding-top: 90px;
// }
//   .footer-end {
//     flex-direction: row;
//   }

//   .footer-email {
//     padding-right: 75px;
//   }
}

footer {
	scroll-snap-stop: always;
	scroll-snap-align: end;
	position: relative;
	height:auto;
	width: 100%;
}

// @media only screen and (max-width: 576px) {
//   .footer-end {

//     flex-direction: column;
//   }

//   .h100 {
//     height: auto;
//   }
//   .section {
//     height: 100vh;
//   }
// }

// .slick-prev {
//   width: auto !important;
//   height: auto !important;
//   top: 40% !important;
// }

// .slick-next {
//   width: auto !important;
//   height: auto !important;
//   top: 40% !important;
// }

// .nav-link:focus,
// .nav-link:hover {
//   color: #000000;
// }

// $accordion-button-focus-border-color: white;
// $accordion-button-focus-box-shadow: none;

// .accordion-button:focus {
//   color: none !important;
//   background-color: none !important;
//   box-shadow: none !important;
//   outline: none !important;
//   box-shadow: none;
// }

// .accordion-button:not(.collapsed) {
//   color: black;
//   background-color: #ffffff;
//   box-shadow: none;
// }

// .accordion-button:after {
//   background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
// }

// .solPageContainer{

// }

.slick-slide{
  background: #FFFFFF;
  opacity: 0.5;
  padding: 0px 10px;
}


.slick-slide.slick-active {
  background: #FFFF;
  padding: 0px 10px;
  opacity: 1;
}


.slick-dots{
  // bottom: -35px !important;
  position: static !important;
  margin-top: 1% !important
}

.slick-dots li button::before{
  font-size: 18px !important;
}


.slick-prev:before {
  content: url(./Assets/Home/previous.svg) !important;
  font-size: 50px !important;
}

.slick-next:before {
  content: url(./Assets//Home/next.svg) !important;
  font-size: 50px !important;
}


.slick-prev {
  width: auto !important;
  height: auto !important;
  top: 40% !important;
  left: 2% !important;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 50px !important;
}

.slick-next {
  width: auto !important;
  height: auto !important;
  top: 40% !important;
  right: 2% !important;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 50px !important;
}

@media (max-width:1200px) and (orientation:landscape){
  .nav-link{
    padding: 0 0.5rem !important;
  }
}

@media (max-width:820px){
  .nav-link{
    padding: 0 0.5rem !important;
  }
}

@media (max-width:820px) and (orientation:landscape){
  .nav-link{
    padding: 0 0.5rem !important;
  }
}


@media (max-width:768px) {
  .nav-link{
    padding: 0 0.5rem !important;
  }
}

@media (max-width:768px) and (orientation:landscape) {
  .nav-link{
    padding: 0 0.5rem !important;
  }
}


@media (max-width:576px){
  .slick-dots{
    margin-top:5% !important
  }

  .slick-prev, .slick-next{
    transform:translate(0,-150%) !important;
  }
}

