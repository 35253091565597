.contactContainer {
  height: auto;
  // background-image: url("../../../Assets/GetSolar/contactPic.png");
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
}

.contactRow {
  height: auto;
  min-height: 85vh;
}

.contactLeft {
  text-align: left;
  padding: 82px 0px 0px 100px;
}

.contactLeft h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 0.02em;
  color: #ffffff;

  span {
    font-weight: 600;
    color: #0e265e;
  }
}

.contactLeft p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 50px;
  /* or 139% */

  letter-spacing: 0.02em;

  color: #ffffff;
}

.contactRight {
  display: flex;
  justify-content: center;
  align-items: start;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 82px;
  padding-bottom: 75px;
  height: 100vh;
  overflow-x: auto;
}

.contactRight::-webkit-scrollbar {
  display: none;
}

.formContainer {
  width: 70%;
  // height:100%;

  // background: white;
  // background: rgba(0,0,0,0);
  // padding:100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// .contactRight {
//   padding-inline:100px;
//   // height: 100%;
// }

.formContainer button {
  width: 100%;
  height: 52px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.02em;
  border: none;
}

.unClickedButton {
  background: rgba(255, 255, 255, 0.08);
  color: #ffffff;
}

.clickedButton {
  background-color: #0e265e;
  color: white;
}

.formButtonLeft {
  padding-right: 0;
}

.formButtonRight {
  padding-left: 0;
}

.formFields {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;

  color: #d8d8d8;
  background: rgba(0, 0, 0, 0);
  border-bottom: 2px solid #d8d8d8 !important;
  border-radius: 0 !important;
}

.fileField {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;

  color: #d8d8d8;
  background: rgba(0, 0, 0, 0);
  border-bottom: none !important;
  border-radius: 0 !important;
  text-align: left;
}

input::placeholder {
  color: #d8d8d8 !important;
}

input.formFields:first-of-type {
  margin-top: 40px;
}

input.formFields:focus {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  background-clip: border-box !important;

  // styling for fonts
  font-family: "Inter";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.02em !important;

  color: #d8d8d8 !important;
  box-shadow: unset !important;
  border-bottom: 2px solid #d8d8d8 !important;
  border-radius: 0 !important;
}

select.formFields:first-of-type {
  margin-top: 40px;
}

select.formFields:focus {
  box-shadow: unset !important;
}

option {
  // background-color:#FFFFFF;
  // color:black;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;

  color: black;
  background: rgba(0, 0, 0, 0);
}

.formFields {
  margin-bottom: 30px;
}

.billDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;

  border-bottom: 2px solid #d8d8d8 !important;
  border-radius: 0 !important;
  padding-bottom: 12%;
  margin-bottom: 8%;
  margin-top: 2%;
  position:relative;
}

.billBox {
  background: rgba(0, 0, 0, 0);
  border: 6px solid grey;
  border-radius: 13%;
  width: 39%;
  /* color: white; */
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.addButton {
  filter: invert(1);
  cursor:pointer;
}

.fileImage{
  width:100%;
  height:100%;
}

.dltButton {
  background-color: red;
  position: absolute;
  top: 0;
  right: 0;
  cursor:pointer;

  img {
    filter: invert(1);
  }
}

.billErrorMsg{
  position:absolute;
  bottom:-5%;
  color:red;
 
}

.submitButton {
  background-color: #0e265e;
  color: white;
  margin-top: 10px;
}

input {
  padding-left: 0 !important;
}

select {
  padding-left: 0 !important;
}

// for mobile landscape
@media (max-width: 900px) and (orientation: landscape) {
  .contactContainer {
    height: auto;
  }

  .contactLeft {
    padding: 82px 0px 0px 7%;

    h1 {
      font-size: 55px;
      line-height: 40px;
    }

    p {
      font-size: 28px;
      line-height: 35px;
    }
  }

  .formContainer {
    width: 88%;
  }
}

// for small mobile device
@media (max-width: 768px) and (orientation: landscape) {
  .contactLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 13%;
    padding-bottom: 13%;
  }
}

// now styling for mobile design

@media (max-width: 576px) {
  // .contactContainer{
  //   background-size:auto 48vh;
  //   background-position:top center;
  // }

  .contactLeft {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    justify-content: start;
    align-items: center;
    padding: 0;
  }
  .contactLeft h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 64.5%;
    /* or 12px */

    text-align: center;
    letter-spacing: 0.08em;
    font-feature-settings: "liga" off;

    color: #ffffff;

    span {
      color: #0e265e;
    }
  }

  .contactLeft p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    /* or 175% */

    text-align: center;
    letter-spacing: 0.54px;

    color: #ffffff;
  }

  .contactRight {
    padding: 0;
    background: rgba(0, 0, 0, 0);
    align-items: start;
    // height: 37vh;
    // height: 45vh;
    height: 60vh;
  }

  .formContainer {
    // padding-inline:1rem;
    // width:100%;
    // height:50%;
    //  width:85%;
    //  height:80%;
    //   padding-inline:1rem;
    //   background: rgba(0, 0, 0, 0.5);
    width: 100%;
    /* height: 80%; */
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    margin: 0 10%;
  }

  .formContainer button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 12px 16px;
    padding: 22px 16px;
    gap: 8px;
    height: 29px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #ffffff;
  }

  .formFields {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;

    color: #ffffff;
    margin-bottom: 1rem;
  }

  .fileField {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #d8d8d8;
    background: rgba(0, 0, 0, 0);
    border-bottom: none !important;
    border-radius: 0 !important;
  }

  input.formFields:focus {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    letter-spacing: 0.02em !important;

    color: #ffffff;
    margin-bottom: 1rem;
  }

  select.formFields:focus {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;

    color: #ffffff;
    margin-bottom: 1rem;
  }

  input.formFields:first-of-type {
    margin-top: 1rem;
  }

  select.formFields:first-of-type {
    margin-top: 1rem;
  }

  // .formFields:first-of-type{
  //   margin-top:1rem;

  // }

  .contactRow{
    height: auto;
    min-height: 75vh;
  }
}
