.visionContainer {
  width: 100%;
  // height: 80vh;
  padding: 0px;
  margin: 0 auto;
  text-align: center;
  // margin-top: 50px;
}

.visionText {
  width: 60%;
  height: 22%;
  text-align: center;
  display: block;
  margin: 0 auto 80px;
}

.visionText h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 3.5px;
  color: #181818;
  text-align: center;
}

.visionText span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 790;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 3.5px;
  color: #0e265e;
}


// removed from the design but keeping it just in case
// .visionText p {
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 200;
//   font-size: 24px;
//   line-height: 36px;
//   /* or 150% */

//   letter-spacing: 1px;

//   color: #181818;
//   margin-bottom:3rem;
// }

.itemContainer {
  width: 100% !important;
  min-height: 350px;
  height: auto;
  text-align: center;
  // padding-inline: 0 !important;
  background: #f0f0f0;
  padding: 30px 50px;

}

.itemContainer img {
  width: 95px;
  height: 84px;
  margin: auto auto 16px;
  display: block;
}

.itemContainer h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 25px;
  color: #000000;
}

.itemContainer p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  /* or 161% */

  color: #000000;
}

// for slick dots

.slick-dots {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  list-style: none;
}

.slick-dots li {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-dots li button {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 0;
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.slick-dots li.slick-active button {
  background-color: #000;
}

// slick dots styling ends

// for mobile screen begins

@media (max-width: 576px) {
  .visionText {
   width:90%;
   
  }

  .visionText h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 64.5%;
    /* or 12px */

    text-align: center;
    letter-spacing: 3.5px;
    font-feature-settings: "liga" off;

    color: #181818;
  }

  .visionText span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 64.5%;
    /* or 12px */

    text-align: center;
    letter-spacing: 3.5px;
    font-feature-settings: "liga" off;

    color: #0e265e;
  }

  .visionText p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #181818;
  }


  // following are the latest addition, although last one was much better

  .itemContainer{
    padding: 30px 35px;
      display:flex !important;
  flex-direction:column;
  justify-content: center;
  align-items: center;

  img{
    margin: auto auto 24px;
  }
  }

  .itemContainer h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    color: #000000;
}

  .itemContainer p{
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
}
