// footer sytling

.footerContainer {
  background: white;
  padding: 0px;

}

.footerContent {
  padding: 25px 130px;
  color: white;
}

.logoColumn {
  padding: 0px;
}

ul {
  text-align: left;
}

.linkColumn {
  padding: 0px;
}

.linkColumn li {
  display: inline-block;
  margin: auto 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  color: #000000;
}

.iconColumn {
  padding: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.iconColumn a {
  margin: auto 10px;
}

.iconColumn a:last-child {
  margin-right: 0px;
}


// styling for my lappy 
@media (max-width:1281px) {
  .linkColumn li{
      font-size: 12px;
  }
}

// mobile landscape and tab styling begins

@media (max-width:1200px){
  .linkColumn ul{
    display:flex;
    flex-flow:row wrap;
    justify-content: space-between;
  }

  .linkColumn li{
    margin:auto 8px;
  }

  .iconColumn a{
    margin:auto 5px;
  }
}

@media (max-width:1200px) and (orientation:landscape){
  .footerContent{
    padding: 24px 95px;
  }

  .linkColumn li{
    margin:auto 3px;
  }

  .iconColumn a{
    margin:auto 10px;
  }
}

@media (max-width:900px){
  .footerContent{
    padding:25px 7%;
  }
  
  .logoColumn{
    display:flex;
    justify-content: start;
  }

  .logoColumn img{
    width:80%;
  }

  .linkColumn li{
    margin: 0 5px;
    font-size: 10px;
    font-weight: 500;
  }

  .iconColumn a{
    margin:auto 8px;
  }
}

@media (max-width:900px) and (orientation:landscape) {

  .footerContent{
    padding:25px 7%;
  }
  
  .logoColumn{
    display:flex;
    justify-content: start;
  }

  .logoColumn img{
    width:80%;
  }

  .linkColumn li{
    margin: 0px 6px;
    font-size: 10px;
    font-weight:500;
  }

  .iconColumn a{
    margin:auto 8px;
  }
 
}




@media (max-width:768px) {
  .footerContent{
    padding:25px 7%;
  }
  
  .logoColumn{
    display:flex;
    justify-content: start;
  }

  .logoColumn img{
    width:80%;
  }

  .linkColumn li{
    margin: 0px 3px;
    font-size: 11px;
    font-weight:500;
  }

  .iconColumn a{
    margin:auto 7px;
  }

  .iconColumn img{
    width:26px;
  }
}

@media (max-width:768px) and (orientation:landscape) {
  .footerContent{
    padding:25px 7%;
  }
  
  .logoColumn{
    display:flex;
    justify-content: start;
  }

  .logoColumn img{
    width:80%;
  }

  .linkColumn li{
    margin: 0px 5px;
    font-size: 10px;
    font-weight: 500;
  }

  .iconColumn a{
    margin:auto 3px;
  }

  .iconColumn img{
    width:26px;
  }
}



// mobile landscape and tab styling ends


@media (max-width:576px){
  .footerContent{
    padding:25px 45px;
  }

  .logoColumn{
    justify-content: center !important;
    margin-bottom: 10px;


  }

  .linkColumn{

    margin-bottom: 25px;
    flex-wrap:wrap;
  }

  .linkColumn ul{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
  }

  .linkColumn li{
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 11px;
/* or 92% */

text-align: center;
font-feature-settings: 'liga' off;

color: #000000;
margin:10px 13px;
  }

  .iconColumn{
    justify-content: center !important;
    margin-bottom: 10px;
  }
}