.careerIntroSection {
  height: 85vh;
  scroll-snap-align: end;
}

.positionSection {
  height: auto;
  background: #f5f5f5;
}

.roleSection {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7%;
  padding-bottom: 7%;
}

.backDrop {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
}

.modalContainer {
  position: fixed;
  z-index: 300;
  top: 10%;
  left: 20%;
  width: 60%;
  padding: 4%;
  background: #ffffff;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 3.5px;

    color: #181818;
    margin-bottom: 1.5%;

    span {
      color: #132da5;
      font-weight: 600;
      margin-left: 1%;
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #3b3a40;
    margin-bottom: 5.6%;
  }

  option.modalField {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Input text */

    color: #8e8e8e;
  }
}

.modalClosePic {
  position: absolute;
  top: 4%;
  right: 4%;
  cursor: pointer;
}

.modalField {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  /* Primeraly/Black */

  color: #383c3e;

  border-bottom: 2px solid #737b7d;
  border-radius: 0 !important;
  margin-bottom: 4%;
}

input.modalField::placeholder {
  color: #383c3e !important;
}

input.modalField:focus {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  /* Primeraly/Black */

  color: #383c3e !important;
  box-shadow: unset !important;
  border-bottom: 2px solid #737b7d !important;
  border-radius: 0 !important;
  margin-bottom: 4% !important;
}

option.modalField {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  /* Input text */

  color: #8e8e8e;
  cursor: pointer;
}

select.modalField:focus {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  /* Input text */

  color: #8e8e8e;
  box-shadow: unset !important;
  cursor: pointer;
}

.modalButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 22px;
  gap: 10px;

  width: 176px;
  height: 56px;

  background: #0e265e;
  cursor: pointer;
  border: none;

  p {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    margin: 0;

    color: #ffffff;
  }
}

.fileUploadDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Input text */

    color: #8e8e8e;
  }
}

.fileUploadBox {
  display: flex;

  justify-content: center;
  align-items: center;
  border: 2px dashed #8e8e8e;
  padding: 7% 5%;
  width: 100%;

  margin-bottom: 2%;
  position:relative;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Input text */

    color: #8e8e8e;
    margin: 0;
    margin-left: 2%;
  }

  .fileUploadContent {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30%;
  }

  .removeFilePic {
    border: 1px solid black;
    background: white;
    width: 2%;
    margin-left: 2%;
    cursor: pointer;
  }

  .errorMsg {
    color: red;
    position:absolute;
    bottom:20%;

  }
}

@media (max-width:900px) and (orientation:portrait) {
  .careerIntroSection{
    height:40vh;
    margin-bottom:2%;
  }
}


@media (max-width:770px) and (orientation:portrait){
  .careerIntroSection{
    height:40vh;
    margin-bottom:4%;
  }


}

@media (max-width: 576px) {

  .careerIntroSection{
    height:85vh;
  }



  .modalContainer {
    top: 23%;
    left: 5%;
    width: 90%;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 64.5%;
      /* or 9px */

      text-align: justify;
      letter-spacing: 0.08em;
      font-feature-settings: "liga" off;

      color: #181818;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      /* or 140% */

      letter-spacing: 0.2px;
      text-transform: capitalize;

      color: #3b3a40;
      margin-top: 4%;
    }
  }

  .modalClosePic {
    top: 2%;
    right: 2%;
    width: 6%;
  }

  .modalField {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Primeraly/Black */

    color: #383c3e;
  }

  input.modalField:focus {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Primeraly/Black */

    color: #383c3e;
  }

  option.modalField {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Primeraly/Black */

    color: #383c3e;
  }

  select.modalField:focus {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    /* Primeraly/Black */

    color: #383c3e;
  }

  .fileUploadBox p {
    margin: 0;
    margin-left: 3%;
  }

  .modalButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 22px;
    gap: 10px;

    width: 105px;
    height: 35px;

    background: #0e265e;

    p {
      font-family: "Kanit";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;

      color: #ffffff;
    }
  }
}
