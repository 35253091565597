.energyIntroContainer {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 100px;
}

.energyLeftColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.energyLeftColumn h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 42px;
  text-align: left;
  letter-spacing: 1.5px;
  color: #181818;
  margin-bottom: 25px;
  span {
    font-weight: 600;
    color: #0e265e;
  }
}

.energyLeftColumn p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  /* or 180% */

  text-align: justify;
  letter-spacing: 0.54px;

  color: #000000;
}

.energyRightColumn img {
  margin-top: 50px;
  width: 100%;
  height: auto;
  //   background: url("../../../../Assets/EnergyFinancing/energyIntroPic.svg");
  //   background-position: center center ;
  //   background-size: cover;
  //   background-repeat: no-repeat;
}

// for tab
@media (max-width:900px) and (orientation:portrait){
  .energyIntroContainer{
    height:auto;
    padding-inline:7%;
  }

  .energyRightColumn{
    display:flex;
    justify-content: center;
    align-items: center;
  }
}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
  .energyIntroContainer{
    height:auto;
    padding-inline:50px;
  }
}



// for small mobile landscape
@media (max-width:768px) and (orientation:landscape){

  .energyIntroContainer{
    height:auto;
    padding-inline:50px;
  }
  .energyLeftColumn{
    order:2;
  }

  .energyRightColumn{
    order:1;
  }
}

// for mobile screen
@media (max-width: 576px) {
  .energyIntroContainer {
    padding-inline: 1rem;
    height:auto;
  }

  .energyLeftColumn {
    order: 2;
  }

  .energyLeftColumn h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 64.5%;
    /* or 12px */

    letter-spacing: 0.08em;
    font-feature-settings: "liga" off;

    color: #181818;

    span {
      font-weight: 600;
      color: #0e265e;
    }

    margin-bottom: 1rem;
  }

  .energyLeftColumn p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
    /* or 203% */

    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: 0.54px;

    color: #000000;
  }

  .energyRightColumn {
    order: 1;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:2rem;
  }

  .energyRightColumn img {
    min-width:255px;
    width:90%;
    height:auto;
  }
}
