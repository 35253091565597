.careerIntroContainer {
  height: auto;
  
}

.carIntroLeft {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 85vh;
  padding-left: 7%;
}

.introBtnDiv{
  display: flex;
  justify-content: start;
}

.carIntroText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 80%;
}

.carIntroHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: 1.5px;
  color: #181818;

  span {
    color: #132da5;
    font-weight: 300;
  }
}

.carIntroParagraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #3b3a40;
  margin-top: 4%;
  margin-bottom: 7.6%;
}

.CareerRightSection{
  background: url("../../../../Assets/Career/Career.png");
  background-position: 30% 80%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 85vh;
  padding: 0;
}

.carIntroButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 22px;
  gap: 10px;
  border: none;
  background: #0e265e;
  margin-right: 25px;
  cursor: pointer;
  p {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    margin: 0;
  }
}

.carIntroRight {
  position: relative;
  height: 85vh;
}

.carIntroPic1 {
  position: relative;

  width: 62%;
  height: auto;
  left: 35%;
  top: 7%;
}

.carIntroPic2 {
  position: absolute;
  width:64%;
  right: 30%;
  top: 24%;
  z-index: 6;
}

.carIntroPic3 {
  // position: relative;
  // left: 40%;
  // top: 5%;
  right: 0;
  position: absolute;
  bottom: 3%;
  width: 56%;
}

// for tab landscape 
@media (max-width:1200px) and (orientation:landscape){
  .carIntroPic3{
    bottom:22%;
  }
}

// for tab medium 
@media (max-width:900px) and (orientation:portrait) {
  .carIntroText{
    width:90%;
  }

  .carIntroLeft{
    height:40vh;

  }

  .carIntroHeading{
    line-height: 38px;
  }

  // .carIntroRight{
  //   height:40vh;
  // }

  .CareerRightSection{
    height:40vh;
    background-position: 26% 80%;
  }

  .carIntroButton{
    p{
      font-size: 13px;
    }
  }

  // .carIntroPic3{
  //   bottom: 19%;
  // }
}


// for medium mobile landscape
@media (max-width:900px) and (orientation:landscape){
  .carIntroText{
    width:76%;
  }

  .carIntroHeading{
    font-size: 17px;
    line-height: 22px;
  }

  .carIntroParagraph{
    line-height: 19px;
    margin-top:0;
    margin-bottom: 5.6%;
    font-size: 12px;
  }

  .carIntroPic3{
    bottom:3%;
  }

  .carIntroButton {
    padding: 10px 22px;
    p{
      font-size: 10px;
    }
    
  }
}

// for tab small
@media (max-width:769px) and (orientation:portrait){

  .carIntroText{
    width:100%;
  }

  .carIntroLeft{
    height:40vh;

  }

  .carIntroRight{
    height:40vh;
  }

  .carIntroPic3{
    bottom:12%;
  }
}


// small mobile landscape 
@media (max-width:768px) and (orientation:landscape){
  .carIntroText{
    width:100%;
  }

  .carIntroHeading{
    font-size: 16px;
    line-height: 20px;
  }

  .carIntroParagraph{
    line-height: 19px;
    margin-top:0;
    margin-bottom: 5.6%;
    font-size: 13px;
  }

  .carIntroButton{
    padding: 8px 17px;
    p{
      font-size: 10px;
    }

  }
  

}
// for mobile view

@media (max-width: 576px) {
  .carIntroLeft {
    order: 2;
    height: auto;
    padding-top: 9%;
  }

  .carIntroText {
    width: 100%;
  }

  .carIntroHeading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    font-feature-settings: "liga" off;

    color: #181818;
    width:86%;

    span {
      color: #132da5;
      font-weight: 300;
    }
  }

  .carIntroParagraph {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */

    text-align: justify;
    letter-spacing: 0.1px;
    text-transform: capitalize;

    color: #3b3a40;
  }

  .carIntroButton {
    padding: 16px 22px;
    gap: 10px;

    width: 131px;
    height: 35px;


    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
     
    }
  }

  .carIntroRight {
    order: 1;
    height: 40vh;
    margin-bottom: 7%;
    margin-top: 10%;
  }

  .carIntroPic1 {
    // position: relative;
    width: 61%;
    height: auto;
    left: 36%;
    top: 4%;
  }

  .carIntroPic2 {
    // position: absolute;
    right: 42%;
    top: 24%;
    // z-index: 6;
    width: 55%;
  }

  .CareerRightSection{
    background: url("../../../../Assets/Career/Career.png");
    background-position: 19% 80%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    padding: 0;
  }


}
