.positionContainer {
  padding-inline: 7%;
  padding-top: 4%;
}

.posIntroText {
  margin-bottom: 4.8%;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 3.5px;

    color: #181818;

    span {
      color: #0e265e;
      font-weight: 600;
      margin-left: 1%;
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* or 188% */

    letter-spacing: 0.2px;

    color: #3b3a40;
  }
}

.roleCol {
  background: #ffffff;
  margin-bottom: 2.4%;
  padding: 3% 3%;
  cursor: pointer;
}

.posFirstCol {
  display: flex;
  justify-content: start;
  align-items: center;

  img {
    margin-right: 7%;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    letter-spacing: 0.183333px;

    color: #000000;
    margin: 0;
  }
}

.roleDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.roleLocation {
  display: none;
}

.posSecondCol {
  display: flex;
  justify-content: start;
  align-items: center;

  img {
    margin-right: 1.5%;
  }

  p {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.528px;

    color: #81838c;
  }
}

.clockPic {
  margin-left: 3.4%;
}

.posThirdCol {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.posDescripText {
  padding-top: 4%;
  width: 67%;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 110%;
    /* or 24px */

    letter-spacing: -0.03em;

    /* Neutral/Black */

    color: #03133d;
    margin-bottom: 3%;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: justify;

    /* Neutral/Grey */

    color: #68718b;
  }

  h2 {
    margin-top: 5%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 110%;
    /* or 24px */

    letter-spacing: -0.03em;

    /* Neutral/Black */

    color: #03133d;
    // margin-bottom: 3%;
  }
}

.posUl {
  padding-left: 3%;

  li {
    display: list-item;
  }
  p {
    margin-bottom: 1%;
  }
}

.posApplyButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 22px;
  gap: 10px;

  width: 167px;
  height: 56px;

  background: #0e265e;
  margin-top: 5%;
  border: none;
  cursor: pointer;

  p {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    margin: 0;
  }
}

// for the tab landscape 
@media (max-width:1200px) and (orientation:landscape){
  .posFirstCol p {
    font-size:20px;
  }
}

// for the small tab landscape 
@media (max-width:1050px) and (orientation:landscape){
  .posFirstCol p {
    font-size:16px;
  }
}



// for the tab portrait 

@media (max-width:900px) and (orientation:portrait){
  .posFirstCol p{
    font-size:19px;
  }
}

// for small tab portrait 
@media (max-width:769px) and (orientation:portrait) {
  .posFirstCol p{
    font-size:17px;
  }

  .posSecondCol p {
    font-size:15px;
  }
}

// for small mobile landscape
@media (max-width:769px) and (orientation:landscape){
  .posFirstCol p{
    font-size:14px;
  }
  
  .posSecondCol p{
    font-size:13px;
  }
}


// for the mobile view

@media (max-width: 576px) {
  .positionContainer {
    padding-inline: 7%;
    padding-top: 13%;
    padding-bottom: 13%;
  }

  .posIntroText {
    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 64.5%;

      letter-spacing: 0.08em;
      font-feature-settings: "liga" off;

      color: #181818;
      // margin-top: 13%;
      margin-bottom: 4%;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      /* or 150% */

      text-align: justify;
      letter-spacing: 0.2px;
      text-transform: capitalize;

      color: #3b3a40;
    }
  }

  .posFirstCol {
    justify-content: start;
    margin-bottom: 4%;
    order: 1;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */

      letter-spacing: 0.183333px;

      color: #000000;
    }
  }

  .roleLocation {
    display: unset;
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: -0.528px !important;

    color: #81838c !important;
    margin-top:2% !important;
  }

  .posSecondCol {
    display: none;
 
  }

  .posThirdCol {
    margin-bottom: 4%;
    order: 2;
  }

  .posDescripText {
    width: 100%;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 110%;
      /* or 13px */

      letter-spacing: -0.03em;

      /* Neutral/Black */

      color: #03133d;
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 110%;
      /* or 13px */

      letter-spacing: -0.03em;

      /* Neutral/Black */

      color: #03133d;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 18px;
      /* or 180% */

      text-align: justify;

      /* Neutral/Grey */

      color: #68718b;
    }
  }

  .posUl {
    li {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  .posApplyButton {
    padding: 16px 22px;
    gap: 10px;

    width: 110px;
    height: 30px;

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }
  }
}
