// .headerSection {
//   height: 15vh;
//   padding-top: 0;
//   display:flex;
//   align-items: center;
// }

.aboutIntroSection {
  height: 85vh;
  // margin-bottom: 40px;
  // padding: 10% 0;
  scroll-snap-align: end;
}

.timelineSection{
  height:88vh;
  display:flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:1400px) {
  .timelineSection{
    height:84vh;
  }
}

// .aboutStatsSection{
//     display:flex;
//     align-items: center;
// }

// for tab
@media (max-width:900px) and (orientation:portrait){
  .aboutIntroSection{
    height:auto;
    margin-top:5%;
  
  }
}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
  .headerSection{
    height:auto;
    margin-bottom:2%;
}

.aboutIntroSection{
  height:auto;
  margin-top:5%;

}

.timelineSection{
  height:100vh;
}
}

@media (max-width:576px) {
  .headerSection{
      height:auto;
  }

  .aboutIntroSection{
    height:auto;
    min-height:90vh;
  }

  .timelineSection{
    height:auto;
  }
}
