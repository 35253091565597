.projectContainer {
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0px;
  padding-inline: 100px;
  // display: flex;
  // flex-flow: row wrap;
  // justify-content: start;
  // align-items: center;
}

.projectTextOne, .projectTextTwo, .projectTextThree, .projectTextFour{

  opacity: 1;
  position:absolute;
  top:30px;
  z-index:2;
 
  transition: opacity 900ms ease-out;
}

.projectSecOne {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projectSecTwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projectContainer p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;

  color: #ffffff;
  padding-left: 30px;
  
}

.projectHeading h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 3.5px;
  color: #181818;
}

.projectHeading span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 556;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 3.5px;
  color: #0e265e;
}

.installLocations{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

 .installLocations p{
  position: relative;
  bottom: -65%;
}

// the background images

.projectOne {
  background: url("../../../../Assets/Home/IndRev.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40%;
  min-height: 100px;
  margin-top: 15px;
  margin-bottom: 40px;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
}

// animation styling begins

.animationContainerOne {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-inline: 25px;
  background: rgba(255, 255, 255, 0.9);
  width: 80%;
  height: 70%;
  position: absolute;
  top:15%;
  z-index:3;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1000ms ease-in-out, transform 700ms ease-in-out;
}

.animationContainerOne h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  color: #0e265e;
}

.animationContainerOne p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 1px;
  padding-left: 0;

  color: #606060;
}

// animation styling ends

.projectTwo {
  background: url("../../../../Assets/Home/Agrivoltaic.jpeg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40%;
  min-height: 100px;
  margin-bottom: 40px;
  padding: 30px 0;
  position:relative;
  overflow: hidden;
}

.animationContainerTwo {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-inline: 25px;
  background: rgba(255, 255, 255, 0.9);
  width: 80%;
  height: 70%;
  position: absolute;
  top:15%;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1000ms ease-in-out, transform 700ms ease-in-out;
}

.animationContainerTwo h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  color: #0e265e;
}

.animationContainerTwo p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 1px;
  padding-left: 0;

  color: #606060;
}

.projectThree {
  background: url("../../../../Assets/Home/CommercialZone.webp");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40%;
  min-height: 100px;
  margin-bottom: 40px;
  padding: 30px 0;
  position:relative;
  overflow: hidden;
}

.animationContainerThree {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-inline: 25px;
  background: rgba(255, 255, 255, 0.9);
  width: 80%;
  height: 70%;
  position: absolute;
  top:15%;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1000ms ease-in-out, transform 700ms ease-in-out;
}

.animationContainerThree h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  color: #0e265e;
}

.animationContainerThree p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 1px;
  padding-left: 0;

  color: #606060;
}

.projectFour {
  background: url("../../../../Assets/Home/truenorth.jpg");
  background-size: cover;
  background-position: 14% 136%;
  width: 100%;
  height: 40%;
  min-height: 100px;
  margin-bottom: 40px;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
}

.animationContainerFour {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-inline: 25px;
  background: rgba(255, 255, 255, 0.9);
  width: 80%;
  height: 70%;
  position: absolute;
  top:15%;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1000ms ease-in-out, transform 700ms ease-in-out;
}

.animationContainerFour h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #0e265e;
}

.animationContainerFour p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 1px;
  padding-left: 0;

  color: #606060;
}

.projectOne:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../Assets/Home/IndRev.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-inline: 0;

  // animation styling

  .animationContainerOne {
    position: static;
    opacity: 1;
    transform: translateX(0);
    height:85%;
  }
  .projectTextOne {
    // display: none;
    opacity:0;
    top:30px;
    
  }

  // animation sytling

  // .hoveredText {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }

  // .hoveredLocation {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 24px;
  //   line-height: 36px;
  //   letter-spacing: 1px;
  // }

  // .hoveredCity {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 300;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }
}

.projectTwo:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../Assets/Home/Agrivoltaic.jpeg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-inline: 0;

  .animationContainerTwo {
    position: static;
    opacity: 1;
    transform: translateX(0);
    height:85%;
  }
  .projectTextTwo {
    // display: none;
    opacity:0;
    top:30px;
  }

  // .hoveredText {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }

  // .hoveredLocation {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 24px;
  //   line-height: 36px;
  //   letter-spacing: 1px;
  // }

  // .hoveredCity {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 300;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }
}

.projectThree:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../Assets/Home/CommercialZone.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-inline: 0;

  .animationContainerThree {
    position: static;
    opacity: 1;
    transform: translateX(0);
    height:85%;
  }
  .projectTextThree {
    // display: none;
    opacity:0;
    top:30px;
  }

  // .hoveredText {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }

  // .hoveredLocation {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 24px;
  //   line-height: 36px;
  //   letter-spacing: 1px;
  // }

  // .hoveredCity {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 300;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }
}

.projectFour:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../Assets/Home/truenorth.jpg");
  background-size: cover;
  background-position: 14% 136%;
  display: flex;
  align-items: center;
  padding-inline: 0;

  .animationContainerFour {
    position: static;
    opacity: 1;
    transform: translateX(0);
    height:85%;
  }
  .projectTextFour {
    // display: none;
    opacity:0;
    top:30px;
  }

  // .hoveredText {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }

  // .hoveredLocation {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 24px;
  //   line-height: 36px;
  //   letter-spacing: 1px;
  // }

  // .hoveredCity {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 300;
  //   font-size: 16px;
  //   line-height: 24px;
  //   letter-spacing: 1px;
  // }
}

// for my lappy
@media (max-width: 1400px) {
  .projectOne,
  .projectTwo,
  .projectThree,.projectFour {
    padding: 10px;
    padding-left:0;
  }

  .animationContainerOne,
  .animationContainerTwo,
  .animationContainerThree,
  .animationContainerFour{
    width: 80%;
    height: 80%;
    top:11%;
  }
}

// for tab portrait
@media (max-width:900px) and (orientation:portrait){
  .projectContainer{
    padding-inline:7%;
  }

  .projectSecOne{
    height:50vh;
  }

  .projectSecTwo{
    height:50vh;
  }

  .animationContainerOne,.animationContainerTwo, .animationContainerThree,.animationContainerFour{

    width: 85%;
    height: 85%;

    h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #0e265e;
    }

    p{
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: justify;
      letter-spacing: 1px;
      padding-left: 0;
    
      color: #606060;
    }
}
}

// for mobile landscape 
@media (max-width:900px) and (orientation:landscape){

  .projectContainer{
    padding-inline:7%;
  }

  
  .animationContainerOne,.animationContainerTwo, .animationContainerThree,.animationContainerFour{
    width: 85%;
    height: 85%;

    h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
  
    color: #0e265e;
    }

    p{
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: justify;
      letter-spacing: 1px;
      padding-left: 0;
    
      color: #606060;
    }
  }

}

@media (max-width:768px) and (orientation:landscape){
  .projectContainer p{
    font-size: 25px;
  }

  .animationContainerOne,.animationContainerTwo, .animationContainerThree, .animationContainerFour{
    width: 85%;
    height: 85%;

    h1{
        font-size:18px;
        margin-bottom:0;
    }

    p{
        font-size:10px;
        line-height:12px;
    }
  }

  
}


// for i phone 14
@media (max-width:735px) and (orientation:landscape){
  .projectContainer{
    padding-inline:0;
  }
}







// for mobile portrait
@media (max-width: 576px) {
  .projectContainer {
    padding-inline: 6%;
    height: auto;
    display: block;
  }

  .projectContainer p {
    padding-left: 0;
  }

  .projectHeading {
    text-align: center;
    margin-bottom: 30px;
  }

  .projectHeading h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 64.5%;
    /* or 12px */

    text-align: center;
    letter-spacing: 3.5px;
    font-feature-settings: "liga" off;

    color: #181818;
  }

  .projectHeading span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 64.5%;
    /* or 12px */

    text-align: center;
    letter-spacing: 3.5px;
    font-feature-settings: "liga" off;

    color: #0e265e;
  }

  .projectOne,
  .projectTwo,
  .projectThree, .projectFour {
    min-height: 200px;
    margin-bottom:0;
  }

  .projectOne:hover{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../../../../Assets/Home/IndRev.png");
    background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-inline: 0; 
  }
   .projectTwo:hover{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../../../../Assets/Home/Agrivoltaic.jpeg");
    background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-inline: 0; 
   }
   .projectThree:hover{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../../../../Assets/Home/CommercialZone.webp");
    background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-inline: 0; 
   }

   .projectFour{
    background-position: 14% 240%;
  }


  .projectFour:hover{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../../../../Assets/Home/truenorth.jpg");
    background-size: cover;
    background-position: 14% 240%;
  display: flex;
  align-items: center;
  padding-inline: 0; 
  }

  .projectOneText,
  .projectTwoText,
  .projectThreeText,
  .projectFourText {
    padding: 10px;
    background: #f5f5f5;
    margin-bottom: 1rem;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      letter-spacing: 1px;

      color: #000000;
    }
  }

  .projectOneText,
  .projectTwoText,
  .projectThreeText,
  .projectFourText {
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1px;

      color: #000000;
    }
  }

  .projectOneText,
  .projectTwoText,
  .projectThreeText,
  .projectFourText {
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: justify;
      letter-spacing: 1px;

      color: #000000;
    }
  }

  .projectOneText,
  .projectTwoText,
  .projectThreeText,
  .projectFourText {
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: justify;
      letter-spacing: 1px;

      color: #000000;
    }
  }
  .installLocations p{
    font-weight:600;
  }
}


