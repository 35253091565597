.headerContainer {
  height:15vh;
  padding: 21px 7%;
  background: #ffffff;
 
}

// .contactHeaderStyling {
//   height:15vh;
//   padding: 21px 7%;
//   background:transparent;
// }

ul {
  margin: 0px !important;
  padding: 0px;
}

ul li {
  display: inline-flex;
  text-decoration: none;
  margin-right: 14px;
  margin-bottom: 0px !important;

  font-style: normal;

  font-size: 16px;

  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  // new styling
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  /* or 312% */

  color: #000000;
}

// .navBarLinkActive {
//   color: #000000 !important;
// }

// .navBarLinkActive {
//   color: #000000 !important;
// }

.activeHeaderLink {
  color: #0e265e !important;
  font-size: 17px;
  font-weight: 900;
}

.joinUsBtn {
  padding: 8px 12px;
  gap: 8px;
  width: 118px;
  height: 45px;
  // background: black !important;
  background: #0e265e;

  border-radius: 2px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #ffffff;
  border: none;
}

a {
  color: #000000;
}

// added this class
.activeLinkItem {
  // background: rgba(154, 154, 154, 0.15);
  cursor: default;
  color: black;
}

.headerLogo {
  padding: 0px;
}
.headerLogo img {
  width: 100%;
  height: auto;
  max-height: 40px;
}



@media (max-width:900px){
  ul li{
    margin-right:0;
    font-size:13px;
  }

  .joinUsBtn{
    width:111px;
  }
}

@media (max-width:900px) and (orientation:landscape) {
  .headerContainer{
    height:auto;
  }

  ul li{
    margin-right:0;
    font-size:11px;
  }
}

@media (max-width:768px) and (orientation:landscape) {
  ul li{
   
    font-size:10px;
  }

  .joinUsBtn{
    width:101px;
    font-size:13px;
  }
}


@media (max-width:576px) {
  .headerContainer{
    height:auto;
  }

  // .contactHeaderStyling {
  //   height:15vh;
  //   padding: 21px 7%;
  //   background:transparent
  // }
}


