.headerSection{
    height: 15vh;
    padding-top: 0;
}

// for tab
@media (max-width:900px) and (orientation:portrait){
    .energyIntroSection{
        height:auto;
    }

    
}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
    .headerSection{
        height:auto;
    }

    .energyIntroSection{
        height:auto;
        margin-top:2%;
        margin-bottom:5%;
    }

}

// for small mobile landscape
@media (max-width:768px) and (orientation:landscape){
    .headerSection{
        height:auto;
    }

    .energyIntroSection{
        height:auto;
        margin-top:2%;
        margin-bottom:5%;
    }

}


// for mobile portrait
@media (max-width:576px) {
    .headerSection{
        height:auto;
    }

    .energyIntroSection{
        margin-bottom: 10%;
        height:85vh;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }
}