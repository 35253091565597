html{
  min-height:100%;
  /* overflow-y: scroll; */
  overflow-x:hidden;
}
body {
  margin:0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Kanit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Kanit';
}

