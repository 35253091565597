.solutionsContainer {
  width: 100%;
  height: 100vh;
  padding-inline: 100px;
}

.solutionsContainer a {
  text-decoration: none;
}

.mobileButtonCol{
  display:none;
}

.joinUsBtn {
  padding: 8px 12px;
  gap: 8px;
  width: 118px;
  height: 40px;
  background: #0e265e;
  border-radius: 2px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #ffffff;
  border: none;
  // float: right;
}

.solutionsContainer h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */
  letter-spacing: 3.5px;
  color: #181818;
  text-align: left;

  span {
    font-weight: 600;
    color: #0e265e;
  }
  margin-bottom: 40px;
}

.energyFirstSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.energySecondSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardContainer img {
  width: 100%;
  height: auto;
}

.solutionText h1,
h3,
h4,
h5 {
  text-align: left;
}

.solutionText h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #000000;
}

.solutionText h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #8b8b8b;
}

.solutionText h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.cardDiv {
  background: #ffffff;
  box-shadow: 0px 7px 14px rgba(166, 166, 166, 0.25);
  border: none;
  margin-bottom: 30px;
}

// .energyFirstSection{
//   padding: 5% 0;
// }

// for tab
@media (max-width:900px) and (orientation:portrait){
  .solutionsContainer{
    height:auto;
    padding-inline:7%;
  }

  .energyFirstSection{
    height:auto;
  }

  .energySecondSection{
    height:auto;
  }
}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
  .solutionsContainer{
    height:auto;
    padding-inline:50px;
  }

  .energyFirstSection{
    height:auto;
    // margin-bottom:2%;
}

.energySecondSection{
    height:auto;
}

.cardDiv{
  margin-bottom:5%;
}

}

// for small mobile landscape
@media (max-width:768px) and (orientation:landscape){

  .solutionsContainer{
    height:auto;
    padding-inline:50px;
  }

  .energyFirstSection{
      height:auto;
  }

  .energySecondSection{
      height:auto;
  }
}


// styling for mobile design

@media (max-width: 576px) {
  .solutionsContainer {
    // width: 100%;
    height: auto;
    padding-inline: 1rem;
  }

  .solutionsContainer h1 {
    margin-top: 50px;
  }

  .joinUsBtn{
    display:none;
  }

  .mobileButtonCol{
    display:unset;
  }

  .joinUsBtnMobile{
    padding: 8px 12px;
    gap: 8px;
    width: 118px;
    height: 40px;
    background: #0e265e;
    border-radius: 2px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-feature-settings: "liga" off;
    color: #ffffff;
    border: none;
  }

  .solutionsContainer h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 64.5%;

    letter-spacing: 0.08em;

    color: #181818;
  }

  .card-body {
    background: #f5f5f5 !important;
  }

  .cardDiv {
    margin-bottom: 10%;
  }

  .cardDiv img{
    height:175px;

  }


  .energyFirstSection {
    height: auto;
  }

  .energySecondSection {
    height: auto;
  }

  .solutionText h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
  }

  .solutionText h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;

    color: #8b8b8b;
  }

  .solutionText h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
  }
}
