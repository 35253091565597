// here the tab's <p> font size was 16 px, causing problem so changed it to 14px

.solPageContainer {
  padding: 0;
  margin-top: 0.5%;
  margin-bottom: 4.5%;
}

.solPageHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 3.5px;

  color: #181818;
  margin-left: 7%;
  // margin-bottom: 3%;
  margin-bottom: 1%;

  span {
    font-weight: 600;
    color: #0e265e;
    margin-right: 5px;
  }
}

.tabsCol {
  margin-bottom: 2%;
}

.tabsRow {
  padding-inline: 7%;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
}

.tabsRow::-webkit-scrollbar {
  display: none;
}

.activeTab {
  // padding: 10% 17%;
  padding: 1% 17%;
  background: #0e265e;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    filter: invert(1);
    padding-top: 10%;
    width: 39px;
    height: 39px;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    color: #ffffff;
    margin-bottom: 0;
  }
}

.inactiveTab {
  // padding: 10% 17%;
  padding: 1% 17%;
  background: #f5f5f5;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 39px;
    height: 39px;
    padding-top: 10%;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
    margin-bottom: 0;
  }
}

.optionCol {
  margin-bottom: 2%;
}

.ulOption {
  padding-inline: 7%;
  display: flex;
  justify-content: start;
  overflow-x: auto;
}

.ulOption::-webkit-scrollbar {
  display: none;
}

.activeOpStyle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 50px;
  color: #0e265e;
  background: #f5f5f5;
  border: 1px solid #0e265e;
  padding-inline: 1.5%;
  display: flex;
  white-space: nowrap;
  height: 2%;
  width: auto;
  margin-right: 2%;
}

.inactiveOpStyle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 50px;
  color: #000000;
  background: #f5f5f5;
  padding-inline: 1.5%;
  display: flex;
  white-space: nowrap;
  height: 2%;
  width: auto;
  margin-right: 2%;
}

.scrollCol {
  height: 49vh;
  overflow: hidden;
}

.sliderCol {
  padding: 0;
}

.slideItem {
  padding: 0 !important;
}

.solLeftCol {
  padding: 2.5% 7.7%;
  // new addition
  height: 60vh;
  overflow-y: scroll;
}

.solLeftCol::-webkit-scrollbar{
  display:none;
}

.firstTextHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 4.5px;
  text-transform: uppercase;

  color: #000000;

  // margin-bottom: 1.5rem;
  // margin-bottom: 0.5rem;
  margin-bottom: 0.3rem;
}

.secondTextHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  // font-size: 35px;
  // font-size: 32px;
  font-size: 28px;
  line-height: 42px;
  display: flex;
  align-items: center;
  letter-spacing: 1.5px;
  text-transform: capitalize;

  color: #0e265e;
  // margin-bottom: 1.5rem;
  margin-bottom: 0.5rem;
}

.solParagraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  // line-height: 31px;
  line-height: 25px;

  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.54px;

  color: #000000;
}

.subHead{
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
}


.subPara{
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 17px;
  line-height: 20px;
}


.solRightCol {
  background: url("../../Assets/Solution/solRooftop.png");
  background-position: 50% 85%;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: revert;
  // height: 100vh;
  padding: 0;
}

// // for mobile landscape
// @media (max-width:768px) and (orientation:landscape){
//   .solRightCol{
//     height:40vh;
//     background-position:50% 85%;
//   }
// }

@media (max-width: 1400px) {
  .solPageContainer {
    padding: 0;
    margin: 0;
  }

  .solLeftCol {
    padding: 1.5% 7.7%;
  
   
  }



  .tabsCol {
    margin-bottom: 1%;
  }

  .optionCol {
    margin-bottom: 1%;
  }
}

// for mobile landscape
@media (max-width: 768px) and (orientation: landscape) {
  .solLeftContent {
    display: flex;
    flex-flow: row;
  }
}

// for mobile portrait

@media (max-width: 576px) {
  .solPageHeading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 64.5%;
    letter-spacing: 0.08em;
    font-feature-settings: "liga" off;

    color: #181818;
    margin-bottom: 5%;

    span {
      font-weight: 600;
    }
  }

  .subHead{
    font-size: 14px !important;
  }
  .subPara{
    font-size: 12px !important;
  }

  .tabsCol {
    margin-bottom: 2.2%;
  }

  .tabsRow {
    --bs-gutter-x: 0.5rem;
  }

  .activeTab {
    padding: 15% 17%;
    img {
      margin-bottom: unset;
      max-width: 45%;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 29px;
      /* or 290% */

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      color: #ffffff;
    }
  }

  .inactiveTab {
    padding: 15% 17%;
    img {
      margin-bottom: unset;
      max-width: 45%;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      color: #000000;
    }
  }

  .optionCol {
    margin-bottom: 5%;
  }
  .activeOpStyle {
    padding-inline: 5%;
    margin-right: 3%;
    font-weight: 400;
    font-size: 10px;
    line-height: 40px;
  }

  .inactiveOpStyle {
    padding-inline: 5%;
    margin-right: 3%;
    font-weight: 400;
    font-size: 10px;
    line-height: 40px;
  }

  .solLeftCol {
    padding: 6% 7.7%;
  }

  .scrollCol {
    padding-left: 0;
    padding-right: 0.5%;
    display: flex;
    justify-content: end;
    height: 50%;
    overflow: hidden;
  }

  .scrollPicStyle {
    height: 300px;
  }

  .firstTextHeading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 4.5px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0.5rem;
  }

  .secondTextHeading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 0.5rem;
  }

  .solParagraph {
    font-family: "Kanit";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: 0.54px;
    color: #535353;
  }

  .solRightCol {
    height: 40vh;
    background-position: 50% 85%;
  }
}
