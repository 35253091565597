.statContainer {
  background-image: url("../../../../Assets/AboutUs/picThree.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 525px;
  // margin-top: 70px;
  padding-inline: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statContainer h1 {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */

  letter-spacing: -0.03em;

  color: #ffffff;
}

.statContainer p {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  color: #ffffff;
}
