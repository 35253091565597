.aboutContainer {
  height: 100%;
  padding: 0;
}

.aboutLeftSection {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 0 4.4% 0 7%;
}

.aboutTextContainer {
  // width: 558px;
  // height: 568px;
  text-align: left;
  // width: 77%;
  // height: 60%;
}

.scrollImage {
  height: 100%;
}

.aboutTextContainer h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  // display: flex;
  // align-items: center;
  letter-spacing: 4.5px;
  text-transform: uppercase;

  color: #000000;
}

.aboutTextContainer p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 32px;
  /* or 203% */
  
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.54px;
  
  color: #000000;
  
}

.aboutTextBottomContainer {
  margin-top: 18px;
  margin-bottom: 18px;
}

.aboutTextBottom {
  display: inline-block;
}

.aboutTextBottom:first-child {
  margin-right: 56px;
}

.aboutTextBottom h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #0e265e;
  margin: 0px;
}

.aboutTextBottom p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #7385ae;
  margin: 0px;
}

.aboutUsArrowButton {
  width: 60px;
  height: 60px;
}

.aboutRightSection {
  background: url("../../../../Assets/Home/aboutUsImage.jpeg");
  background-position: 50% 85%;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: revert;
  height: 100vh;
  padding: 0;
}

// design for 1220 width(xl) screen begins

@media (max-width: 1400px) {
  .aboutLeftSection {
    padding: 0 5.4% 0 7%;
  }

  .scrollImage {
    height: 435px;
  }

  .aboutTextContainer h6 {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 4px;
  }

  .aboutTextContainer p {
    font-size: 13px;
    line-height: 24px;
  }

  .aboutTextBottom h5 {
    font-size: 30px;
    line-height: 40px;
  }

  .aboutUsArrowButton {
    width: 45px;
    height: 45px;
  }
}

// design for my screen done

// for tab portrait
@media (max-width:900px){
  .aboutRightSection{
    height:auto;
  }
}

// responsive design begins

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
  .aboutRightSection{
    height:auto;
  }
}

@media (max-width:768px) and (orientation:landscape){
  .aboutLeftSection {
    padding: 5% 5.4% 5% 7%;
    order:2;
}
  
  .aboutRightSection{
    height:60vh;
    order:1;
  }
}



// for mobile portrait
@media (max-width: 576px) {
  .aboutLeftSection {
    padding: 30px 15px;
    order: 2;
  }

  .aboutTextContainer {
    padding: 0;
  }

  .scrollColumn {
    display: flex;
    justify-content:start;
    padding: 0;
  }

  .scrollImage {
    height: 450px;
  }

  .aboutTextContainer h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    letter-spacing: 4.5px;
    text-transform: uppercase;

    color: #000000;
  }

  .aboutTextContainer p {
    // new styling
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 23px;
    /* or 135% */
    text-align: justify;
    letter-spacing: 0.54px;

    color: #535353;
  }

  .aboutTextBottomContainer {
    margin-top: 14px;
    margin-bottom: 12px;
  }

  .aboutTextBottom {
    display: inline-block;
  }

  .aboutTextBottom:first-child {
    margin-right: 56px;
  }

  .aboutTextBottom h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #0e265e;
    margin: 0px;
  }

  .aboutTextBottom p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #7385ae;
    margin: 0px;
  }

  .aboutUsArrowButton {
    width: 24px;
    height: 24px;
  }

  .aboutRightSection {
    background: url("../../../../Assets/Home/aboutUsImage.jpeg");
    background-position: 50% 85%;
    background-size: cover;
    background-repeat: no-repeat;
    // background-attachment: revert;
    height: 40vh;
    padding: 0;
    order: 1;
  }
}
// mobile design css done
