section {
  // display:flex;
  height:100vh;
  // padding-top: 110px;
  scroll-snap-align: start;
  scroll-snap-type: y mandatory;
  // margin-top:40px;
  // margin-bottom:40px;
  // padding-top:3.5rem;
}

.headerSection {
  height: 15vh;

  padding-top: 0;
  display: flex;
  align-items: center;
}

.homeIntroSection {
  height: 85vh;
  scroll-snap-align: end;
}

.projectSection{
  height:220vh;

}

.visionSection {
  display: flex;
  align-items: center;
}


// for tabs 
@media (max-width:900px) and (orientation:portrait){
  .headerSection{
    height:auto;
  }

  .homeIntroSection{
    height:auto;
    margin-bottom:9%;  
    margin-top:4%;
    // to be continued
  }

  .aboutUsSection{
    height:auto;
    margin-bottom:15%; 
  }

  .visionSection{
    height:auto;
    margin-bottom:15%; 
  }

  .projectSection {
    padding: 0;
    // height: 150vh;
    height:auto;
    margin-bottom:30%; 
  }

  .whyNleSection{
    height:auto;
  }

}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
  .headerSection{
    height:auto;
  }

  .homeIntroSection{
    height:auto;
    margin-bottom:9%;  
    margin-top:4%;
    // to be continued
  }

  .aboutUsSection{
    height:auto;
    margin-bottom:15%; 
  }

  .visionSection{
    height:auto;
    margin-bottom:15%; 
  }

  .projectSection {
    padding: 0;
    // height: 150vh;
    height:auto;
    margin-bottom:30%; 
  }

  .whyNleSection{
    height:auto;
  }

}


// for mobile portrait
@media (max-width: 576px) {

  .headerSection{
    height:auto;
  }

  .homeIntroSection{
    height:auto;
    margin-bottom:9%;  
    // to be continued
  }

  .aboutUsSection{
    height:auto;
    margin-bottom:30%; 
  }

  .visionSection{
    height:auto;
    margin-bottom:40%; 
  }

  .projectSection {
    padding: 0;
    // height: 150vh;
    height:auto;
    margin-bottom:30%; 
  }

  .whyNleSection{
    height:auto;
  }

  
}
