.timelineContainer {
  height: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // position: relative;
}

.timelineContainer h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: 1.5px;

  color: #000000;
  text-align: center;
}

.timeCompoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 74%;
}

.columnBox {
  position: relative;
  width:90%;
}

.timelineRow{
  display:flex;
  justify-content: center;
}

.timeText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timelineContainer img {
  margin-bottom: 0.75rem;
}

.timelineContainer h2 {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 1.5px;
  text-align: center;

  color: #000000;
  // position: relative;
  // left: 95px;
  z-index: 5;
  background: #f5f5f5;
}

.timelineContainer p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 135%;
  /* or 19px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Neutral/Grey */

  color: #68718b;
  margin-bottom: 27.5px;
}

.timeYear {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineStyle {
  width: 75%;
  z-index: 1;
  position: absolute;
  bottom: 7%;
  left: 14%;
}

// styling according to sid's screen

// @media (max-width:1440px) and (min-height:848px){
//   .lineStyle{
//     bottom:36.3%;
//   }
// }

// styling for screen upto 1400px

@media (max-width: 1400px) {
  .timelineContainer h2 {
    left: 87px;
  }

  // .lineStyle {
  //   width: 70%;
  //   bottom: 30%;
  // }
}

// for tab landscape

@media (max-width: 1200px) and (orientation: landscape) {
  .timelineContainer h2 {
    left: 80px;
  }

  // .lineStyle{
  //   bottom: 35.3%;
  // }
}

// for i pag mini
@media (max-width: 1025px) and (orientation: landscape) {
  .timelineContainer h2 {
    left: 70px;
  }

  // .lineStyle{
  //   bottom: 34.2%;
  // }
}

// for tab
@media (max-width: 900px) and (orientation: portrait) {
  .timelineContainer h2 {
    left: 52px;
  }


  .timelineContainer h1{
    font-size: 19px;
    line-height: 22px;
  }

  .lineStyle{
    // bottom:38.2%;
    bottom: 7%;
  }
}

// for mobile landscape
@media (max-width: 900px) and (orientation: landscape) {
  .timelineContainer h2 {
    left: 60px;
  }

  // .lineStyle{
  //   bottom:20.4%;
  // }
}

// for iphone mini
@media (max-width: 812px) and (max-height: 376px) and (orientation: landscape) {
  // .lineStyle{
  //   bottom:19.3%;
  // }
}

// for my phone
@media (max-width: 810px) and (max-height: 413px) and (orientation: landscape) {
  // .lineStyle{
  //   bottom:22%;
  // }
}

// for small tab
@media (max-width: 768px) and (orientation: portrait) {
  // .lineStyle{
  //   bottom:35.4%;
  // }

  .timelineContainer h1{
    font-size: 17px;
    line-height: 25px;
  }
}

// for small mobile landscape
@media (max-width: 768px) and (orientation: landscape) {
  .timeCompoContainer {
    width: 90%;
  }
  .timelineContainer {
    h1 {
      font-size: 15px;
      line-height: 19px;
    }

    p {
      font-size: 12px;
    }

    h2 {
      font-size: 15px;
      line-height: 23px;
      left: 53px;
    }
  }

  // .lineStyle{
  //   bottom:27.3%;
  // }
}

// for himanshu device
@media (max-width: 666px) and (orientation: landscape) {
  // .lineStyle{
  //   bottom:25.5%;
  // }
}

// for mobile portraits
@media (max-width: 576px) {
  .outerTimeContainer {
    margin: 11% auto;
  }

  .timeCompoContainer {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .timelineRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .timelineContainer h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.5px;

    color: #000000;
  }

  .timelineContainer p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 135%;
    /* or 16px */

    display: flex;
    align-items: center;
    text-align: center;

    /* Neutral/Grey */

    color: #68718b;
  }

  .timelineContainer h2 {
    position: relative;
    left: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 1.5px;

    color: #000000;

    // position: static;
  }

  .timeText {
    width: 55%;
  }

  .timeYear {
    margin: 0;
  }

  .lineStyle {
    position: absolute;
    z-index: 30;
    transform: rotatez(90deg);
    /* width: 5px; */
    /* left: -8px; */
    // right: -15px;

    width: 137%;
    top: 49%;
    left: -51.5%;
    z-index: 1;
  }
}

@media (max-width: 400px) {
  .lineStyle {
    width: 147%;
    left: -56.8%;
  }
}

@media (max-width: 360px) {
  .lineStyle {
    left: -62%;
    width: 158%;
  }
}

@media (max-width: 320px) {
  .lineStyle {
    left: -67%;
    width: 166%;
  }
}
