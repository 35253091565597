

.contactFormSection{
    min-height:85vh;
    background-image: url("../../Assets/GetSolar/getsolar.jpg");
    background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display:flex;
    justify-content: center;
    align-items: end;
}

// for mobile landscape
@media (max-width:900px) and (orientation:landscape){
    .contactFormSection{
        height:auto;
     
    }
}

// for small mobile landscape
@media (max-width:768px) and (orientation:landscape){
    .contactFormSection{
        height:auto;
        background-size: 100% 65%;
    background-position: top center;
    margin-bottom:15%;
    }
}

@media (max-width:576px) {
    .headerSection{
        height:auto;
        
    }

    .contactFormSection{
        height:90vh;
        background-size:auto 100vh;
        background-position:top center;
      }
}
