.introContainer {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.introContainer img {
  min-width: 200px;
  width: 16.2%;
  height: auto;
  margin-bottom: 1rem;
}

.introContainer p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 60px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #000000;
}

// // for tabs
// @media (max-width:900px) and (orientation:portrait){
//   .introContainer{
//     height:auto;
//   }
// }


// for mobile devices
@media (max-width: 576px) {
 

  .introContainer img{
    min-width:170px;
  }
 
  .introContainer p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;

    text-align: center;
    letter-spacing: 0.02em;

    color: #000000;
  }
}


