.aboutMsgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-inline: 400px;
  height: 85vh;
}

.aboutMsgContainer h1 {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 34px;
  /* identical to box height, or 94% */

  text-align: center;
  letter-spacing: 3px;

  /* Text */

  color: #363940;
  

  span {
    color: #132da5;

  }

  margin-bottom: 24px;
}

.aboutMsgContainer p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  text-align: center;

  /* Neutral/Grey */

  color: #68718b;
  margin-bottom: 48px;
}

.aboutMsgContainer a{
   display: flex;
   justify-content: center;
}

.getSolar{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 22px;
gap: 10px;

width: 301px;
height: 56px;

background: #0E265E;

font-family: 'Kanit';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
text-decoration: none !important;
}
